import {
  wrapModule,
  getModuleEnvironment,
} from '@grabjs/mobile-kit-bridge-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { getParamsFromUrl } from '../utils/util';
import * as sessionStore from '../utils/sessionStore';
import { focusTypes } from '../../pages/poi-detail/helper/constant';

const ACCESS_TOKEN = '';

export function getDeviceType(global = window) {
  const { userAgent } = global.navigator;
  const isAndroid =
    userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return {
    isAndroid,
    isiOS,
  };
}

const isBelowVersion = version => {
  try {
    const { userAgent } = global.navigator;
    const appVersionMatch =
      userAgent && userAgent.match(/(Grab[A-Za-z]*|MoveIt)\/(\d+\.\d+\.\d+)/);

    const appVersion = appVersionMatch
      ? appVersionMatch[2].split('.').map(Number)
      : null;

    if (!appVersion) return true;

    const compareVersion = version.split('.').map(Number);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < compareVersion.length; i++) {
      if (compareVersion[i] > appVersion[i]) {
        return true;
      }
      if (compareVersion[i] < appVersion[i]) {
        return false;
      }
    }

    // If we got this far, the versions are equal
    return false;
  } catch (err) {
    console.error('isBelowVersion error:', err);
    return true;
  }
};

const generateSuccessResponse = result => ({
  isSuccess: true,
  result,
});

const createMockedModule = (moduleName, mockMethods) => {
  if (!getModuleEnvironment(window, moduleName)) {
    window[`Wrapped${moduleName}`] = {
      invoke: async method => {
        if (mockMethods[method]) return mockMethods[method]();
        throw new Error(`Unexpected method: ${method}`);
      },
    };
  }
};

export const wrapNativeModule = JSInterfaceName => {
  const wrappedModuleName = `Wrapped${JSInterfaceName}`;
  if (!window[wrappedModuleName]) wrapModule(window, JSInterfaceName);

  const inApp = () =>
    getModuleEnvironment(window, JSInterfaceName) !== undefined;

  // mock
  const urlParams = new URLSearchParams(window.location.search);
  const shouldBeMocked = urlParams.get('mock') >= 1;
  if (shouldBeMocked || !inApp()) {
    createMockedModule('AuthModule', {
      getAccessToken: () => ({
        result: urlParams.get('token') || ACCESS_TOKEN,
      }),
    });

    createMockedModule('LocationModule', {
      getCoordinate: () => ({ result: {} }),
    });

    createMockedModule('AnalyticsModule', {
      track: () => ({ result: {} }),
    });

    createMockedModule('GeoPageStyle', {
      setPageTopBarStyle: () => ({ result: {} }),
      showPageBottomBar: () => ({ result: {} }),
    });
  }

  const invoke = async (name, params) => {
    console.time(`${name} time`);

    let generatedRes = null;
    let response = null;
    try {
      response = await window[wrappedModuleName].invoke(name, params);
      generatedRes = generateSuccessResponse(response.result);
    } catch (error) {
      if (name !== 'postPageState') {
        console.error(
          `[invoke ${name} error]: error: ${error}, params: ${JSON.stringify(
            params,
          )}, response: ${JSON.stringify(response)} `,
        );
        generatedRes =
          error && /Unexpected method/.test(error.message || '')
            ? { isSuccess: false, isMethodNotExistError: true }
            : { isSuccess: false };
      }
    } finally {
      //
    }
    console.timeEnd(`${name} time`);

    return generatedRes;
  };

  return { invoke, inApp };
};

export const getAccessToken = async () => {
  const { invoke, inApp } = wrapNativeModule('AuthModule');
  const { result = '' } = await invoke('getAccessToken');
  const accessToken = result;
  const isInApp = inApp();
  return { accessToken, isInApp };
};

export const getCoordinate = async () => {
  const lsCoordinate = sessionStore.get('coordinate');
  if (!lsCoordinate) {
    const { invoke } = wrapNativeModule('LocationModule');
    const { result } = await invoke('getCoordinate');
    if (result && result.latitude && result.longitude) {
      sessionStore.set('coordinate', result);
    }
    return result;
  }
  return lsCoordinate;
};

export const getLocation = async (retryCount = 0) => {
  const MAX_RETRIES = 3;
  const result = await getCoordinate();

  if (result && result.latitude && result.longitude) {
    return result;
  }

  if (retryCount < MAX_RETRIES) {
    try {
      datadogRum.addAction('get_coordinate_null', {
        result,
      });
    } catch (err) {
      // eslint-disable-next-line no-empty
    }

    return getLocation(retryCount + 1); // increment retryCount and call function again
  }

  return null;
};

export const geoPOIListTabFallbackIndexFF = async () => {
  const { isiOS } = getDeviceType();
  // if iOS version is below FF version, will trow error from native
  if (isiOS && isBelowVersion('5.303.0')) {
    return JSON.stringify({ poiDetailDisableBottomButton: false });
  }
  const { invoke } = wrapNativeModule('ExperimentModule');
  const { result } = await invoke('getStringValue', {
    name: 'geoPOIListTabFallbackIndex',
    defaultValue: '{}',
  });
  console.log('result =>', result);
  return result;
};

export const geoPOISharingEnabledFF = async () => {
  const { invoke } = wrapNativeModule('ExperimentModule');
  const { result } = await invoke('getBoolValue', {
    name: 'geoPOISharingEnabled',
    defaultValue: false,
  });
  return result;
};

export const getCurrentLanguageCode = async () => {
  try {
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;

    return lang.split('-')[0];
  } catch (err) {
    return 'en';
  }
};

/**
 * Function to control the display of bottombar.
 *
 * @param {Object} params
 * @param {boolean} params.needShow - This can be true or false.
 * @returns
 */
export const showPageBottomBar = async params => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('showPageBottomBar', params);
  return res;
};

/**
 * Function to control the display of topbar.
 *
 * @param {Object} params - Contains the style property to determine how the icons are displayed.
 * @param {number} params.style - This can be 0, 1, or 2.
 *     0: Show both the left and the right icons
 *     1: Hide the icons
 *     2: Show only the left icon
 * @returns
 */
export const setPageTopBarStyle = async params => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('setPageTopBarStyle', params);
  return res;
};

export const openURL = async url => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('openURL', { url, keepCurrent: true });
  return res;
};

/**
 *
 * @param {{ poiId: string }} params
 * @returns { name: string, combineAddress: string, latlng: { latitude: string, longitude: string } }
 */
export const getPoiData = async params => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('getPoiData', params);
  return res;
};

export const postPageState = async params => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('postPageState', params);
  return res;
};

export const trackEvent = async ({ name, params = {} }) => {
  try {
    const { isiOS } = getDeviceType();
    const { invoke } = wrapNativeModule('AnalyticsModule');

    const transformedParams = Object.keys(params || {}).reduce((acc, key) => {
      const value = params[key];
      if (!value) return acc;
      if (typeof value === 'object') {
        acc[key] = JSON.stringify(value);
      } else {
        acc[key] = `${value}`;
      }
      return acc;
    }, {});

    let eventName;
    if (isiOS) {
      eventName = `leanplum.${name}`;
    } else {
      eventName = name;
    }
    const res = await invoke('track', {
      analyticsEvent: {
        name: eventName,
        params: { STATE_NAME: 'PLACE_DETAILS', ...transformedParams },
      },
    });
    return res;
  } catch (err) {
    console.error('trackEvent error:', err);
    return null;
  }
};

const groupTrackingDataInUrlSearchParams = () => {
  const dataObj = getParamsFromUrl();
  const {
    poiId,
    source_app: sourceApp,
    transportation_type: transportationType,
    is_shared_otf: isSharedOTF,
    seq_id: poiDetailsSeqId, // Tracking data generated by native client, similar to session IDs.
  } = dataObj;
  const data = {
    VERTICAL_TYPE: sourceApp,
    FOCUS_TYPE: focusTypes[transportationType],
    POI_ID: poiId,
    POI_DETAILS_SEQ_ID: poiDetailsSeqId,
    IS_SHARED_OTF: isSharedOTF,
  };
  window.poiDetailGlobalTrackingdata = data;
  return data;
};

groupTrackingDataInUrlSearchParams();

export const trackEventForPoiDetail = async ({ name, params = {} }) => {
  try {
    const dataFromSearchParams = window.poiDetailGlobalTrackingdata;
    const asyncData = window.PoiDetailGlobalTrackingdataAsync || {};
    trackEvent({
      name,
      params: {
        ...params,
        ...dataFromSearchParams,
        ...asyncData,
      },
    });
  } catch (err) {
    console.error('trackEventForPoiDetail error:', err);
  }
};

export const geoGrabMapStyleURLFF = async () => {
  if (!window.geoGrabMapStyleURL) {
    const { invoke } = wrapNativeModule('ExperimentModule');
    const { result } = await invoke('getStringValue', {
      name: 'geoGrabMapStyleURL',
      defaultValue: '',
    });
    window.geoGrabMapStyleURL = result;
    return result;
  }
  return window.geoGrabMapStyleURL;
};

// geoGrabMapStyleURLFF();

export const getSafeArea = async () => {
  const { invoke, inApp } = wrapNativeModule('GeoPageStyle');
  const { result = '' } = await invoke('getSafeArea');
  const isInApp = inApp();
  return { result, isInApp };
};

export const changeLoadingState = async loading => {
  const { invoke, inApp } = wrapNativeModule('GeoPageStyle');
  const { result = '' } = await invoke('changeLoadingState', {
    needShow: loading,
  });
  const isInApp = inApp();
  return { result, isInApp };
};

export const share = async () => {
  const { invoke } = wrapNativeModule('GeoPageStyle');
  const res = await invoke('share');
  return res;
};
